import { useEffect, useState } from "react";
import Form from "./Form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { createFeatures, deleteConfiguratorFeatureCustomField, fetchConfiguratorFeaturesData } from "../../services/apiService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import CustomFieldCreateBtn from "../CustomFieldCreateBtn";

interface FeatureFormProps {
    setError: any;
    setIsError: any;
}

const FeatureForm: React.FC<FeatureFormProps> = ({ setError, setIsError }) => {
    const [searchParams] = useSearchParams();
    const code = searchParams.get('code');
    const [loading, setLoading] = useState<boolean>(true);
    const [featureCount, setFeatureCount] = useState(0);
    const [storiesOption, setStoriesOption] = useState([]);
    const [featureData, setFeatureData] = useState<any>([]);
    const navigate = useNavigate();

    const fields = [
        { name: 'story_name', label: 'Select Story', placeholder: 'Select Story', type: 'select', value: storiesOption },
        { name: 'name', label: 'Name', placeholder: 'Feature name', disable: true },
        { name: 'audio_url', label: 'Audio url', placeholder: 'Audio url' },
        { name: 'animation type', label: 'Animation type', placeholder: 'Animation type' },
        { name: 'feature_type', label: 'Feature type', placeholder: 'Feature type' },
        { name: 'icon_url', label: 'Icon url', placeholder: 'Icon url' },
        { name: 'object_url', label: 'Object url', placeholder: 'Object url', value: '', customField: false }
    ];
    const [fieldsCount, setFieldsCount] = useState<{ [key: string]: typeof fields }[]>([]);

    useEffect(() => {
        setFeatureCount(featureData.length);
    }, [featureData]);

    const handleFeatureSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            const result = await createFeatures(featureData, code);
            if (result.status == 200) {
                navigate(`/configurator/form?page=objects&code=${code}`)
            }
        } catch (error) {
            setIsError(true);
            if (error instanceof Error) {
                setError(error.message);
            } else {
                setError("An unexpected error occurred");
            }
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const getFeatureData = async () => {
            try {
                const result = await fetchConfiguratorFeaturesData(String(code));
                if (result.status == 200) {
                    setFeatureData(result.data.features);
                    setStoriesOption(result.data.stories);
                }
            } catch (error) {
                setIsError(true);
                if (error instanceof Error) {
                    setError(error.message);
                } else {
                    setError("An unexpected error occurred");
                }
            } finally {
                setLoading(false);
            }
        };
        if (code != null) {
            getFeatureData();
        }
    }, [code, setError, setIsError]);

    useEffect(() => {
        if (featureCount === 0 && featureData.length === 0) {
            setFieldsCount([{ feature0: [...fields] }]);
        } else if (featureData.length > 0) {
            const initialFields = Array.from({ length: featureCount }, (_, index) => ({
                [`feature${index}`]: [...fields],
            }));

            initialFields[0] && featureData.forEach((data: any, index: any) => {
                const featureFields = initialFields[index][`feature${index}`] || [];
                const featureCustomFields = data.feature_custom_fields || {};
                const initialCustomFields = Object.values(featureCustomFields).map((customField: any) => ({
                    name: customField.attribute_value,
                    label: customField.attribute_value,
                    placeholder: customField.attribute_value,
                    value: customField.value,
                    customField: true,
                    type: customField.field_type,
                    id: customField.id
                }));

                if (featureFields) {
                    featureFields.push(...initialCustomFields);
                }
            });
            setFieldsCount(initialFields);
        } else if (fieldsCount.length < featureCount) {
            setFieldsCount(prev => {
                const newFields = Array.from({ length: featureCount - prev.length }, (_, index) => ({
                    [`feature${prev.length + index}`]: [...fields]
                }));
                return [...prev, ...newFields];
            });
        }

    }, [featureCount, featureData]);

    const handleFeatureChange = (
        e: React.ChangeEvent<HTMLInputElement>,
        index: number,
        field: boolean
    ) => {
        const { name, value, type } = e.target;

        setFeatureData((prev: any) => {
            const updatedFeatures = [...prev];
            const feature = updatedFeatures[index] || {};
            feature[name] = value;
            if (field) {
                const featureCustomFields = feature.feature_custom_fields || {};
                const fieldIndex = Object.keys(featureCustomFields).findIndex(key => featureCustomFields[key].attribute_value === name);
                if (fieldIndex !== -1) {
                    featureCustomFields[fieldIndex] = {
                        ...featureCustomFields[fieldIndex],
                        value: value,
                    };
                } else {
                    featureCustomFields[Object.keys(featureCustomFields).length] = {
                        id: featureCustomFields[Object.keys(featureCustomFields).length]?.id,
                        attribute_value: name,
                        field_type: type,
                        value: value,
                    };
                }
                feature.feature_custom_fields = featureCustomFields;
            }
            updatedFeatures[index] = feature;
            return updatedFeatures;
        });
    };

    const handleFieldsDeletion = (featureKey: string) => {
        const featureIndex = fieldsCount.findIndex(item => featureKey in item);
        if (featureIndex === -1) {
            console.log('Story key not found');
            return;
        }
        setFeatureData((prev: any) => {
            const updatedFeatures = prev.filter((_: any, index: number) => index !== featureIndex);
            return updatedFeatures;
        });
        setFieldsCount((prev) => {
            const newFieldsCount = prev.filter((feature) => {
                return Object.keys(feature)[0] !== featureKey;
            });
            return newFieldsCount;
        });
        setFeatureCount((prev: any) => prev - 1);
    };

    const handleDeleteCustomField = (id: any, name: any, index: any) => {
        const fieldIndex = fieldsCount[index][`feature${index}`].findIndex((item: { name: any }) => item.name === name);
        if (fieldIndex !== -1 && fieldIndex) {
            setFeatureData((prev: any[]) => {
                const updatedFeatre = prev.map(feature => {
                    const featureCustomFields = [...(feature.feature_custom_fields || [])];
                    const fieldIndex = featureCustomFields.findIndex((item: { attribute_value: any }) => item.attribute_value === name);
                    if (fieldIndex !== -1) {
                        featureCustomFields.splice(fieldIndex, 1);
                    }
                    return {
                        ...feature,
                        feature_custom_fields: featureCustomFields
                    };
                });
                return updatedFeatre;
            });
            setFieldsCount((prev: any) => {
                const updatedFeatre = [...prev];
                const feature = updatedFeatre[index][`feature${index}`];
                feature.splice(fieldIndex, 1)
                return updatedFeatre;
            });
        }
        if (id !== undefined) {
            deleteConfiguratorFeatureCustomField(id);
        }
    }

    const handleFieldCreateSubmit = (input: any, index: any) => {
        setFeatureData((prev: any[]) => {
            const updatedFeature = [...prev];
            const feature = updatedFeature[index] || {};
            const featureCustomFields = feature.feature_custom_fields || {};
            featureCustomFields[Object.keys(featureCustomFields).length] = {
                id: featureCustomFields[Object.keys(featureCustomFields).length]?.id,
                attribute_value: input.name,
                field_type: input.type,
                value: '',
            };
            feature.feature_custom_fields = featureCustomFields;
            updatedFeature[index] = feature;
            return updatedFeature;
        });
    }

    return (
        <form onSubmit={handleFeatureSubmit}>
            <div className="px-10 flex justify-between items-center">
                <button
                    type='button'
                    onClick={() => setFeatureCount((prev: any) => prev + 1)}
                    className="text-blue-600 bg-blue-700 border border-blue-600 hover:bg-blue-800 hover:text-white bg-transparent rounded-lg px-3 py-1.5 text-center text-sm"
                >
                    <FontAwesomeIcon icon={faPlus} className='text-sm' />  Add feature
                </button>
                <button
                    type="submit"
                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                >
                    Save feature
                </button>
            </div>
            <div className='h-[73vh] w-full overflow-y-auto fixed bottom-0'>
                {Array.isArray(fieldsCount) && fieldsCount.map((fieldsObj, index) => {
                    const featureKey = Object.keys(fieldsObj)[0];
                    return (
                        <div key={index}>
                            <div className="px-10 py-5 text-lg flex">
                                <span className='mr-4 text-xl'>Feature {index + 1}</span>
                                {/* {index !== 0 && <button type="button" onClick={() => handleFieldsDeletion(featureKey)} className="flex items-center"><FontAwesomeIcon icon={faTrash} className='text-sm' /></button>} */}
                            </div>
                            <div className='w-full h-auto flex'>
                                <div className='w-full px-5 flex flex-wrap'>
                                    <Form fields={fieldsObj[featureKey]} handleChange={(e: React.ChangeEvent<HTMLInputElement>, field) => handleFeatureChange(e, index, field)} data={featureData[index] ?? []} handleDeleteCustomField={(id: any, name: any) => handleDeleteCustomField(id, name, index)} />
                                    <div className="mb-5 mx-5 w-1/5 items-end justify-end flex">
                                        <CustomFieldCreateBtn index={index} keyName={`feature${index}`} setFieldsCount={setFieldsCount} handleFieldCreateSubmit={(inputValue: any) => handleFieldCreateSubmit(inputValue, index)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </form>
    );
};

export default FeatureForm;