import { useEffect, useState } from "react";
import CustomFieldCreateBtn from "../CustomFieldCreateBtn";
import Form from "./Form";
import { createProject, deleteConfiguratorProjectCustomField, fetchConfiguratorProjectData, fetchOptionsAccount } from "../../services/apiService";
import { useNavigate, useSearchParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';

interface ProjectFormProps {
    setError: any;
    setIsError: any;
}

const ProjectForm: React.FC<ProjectFormProps> = ({ setError, setIsError }) => {
    const [projectCount, setProjectCount] = useState(0);
    const [projectData, setProjectData] = useState<any[]>([]);
    const [accountOption, setAccountOption] = useState([]);
    const navigate = useNavigate();
    const defaultFields = [
        { name: 'project_name', label: 'Project name', placeholder: 'Project name', type: "text" },
        { name: 'code', label: 'Code', placeholder: 'Project code' },
        { name: 'division', label: 'Division', placeholder: 'Project division' },
        { name: 'product_model', label: 'Product model', placeholder: 'Product model' },
        { name: 'bg_image_url', label: 'Background image url', placeholder: 'Background image URL' },
        { name: 'onboarding_audio_url', label: 'Onboarding audio url', placeholder: 'Onboarding audio URL' },
        { name: 'android_app_url', label: 'Android app url', placeholder: 'Android app URL' },
        { name: 'ios_app_url', label: 'IOS app url', placeholder: 'iOS app URL' },
        { name: 'webgl_app_url', label: 'WebGL app url', placeholder: 'WebGL app URL' },
        { name: 'branding_bg_url', label: 'Branding background url', placeholder: 'Branding background URL' },
        { name: 'tracking_mode', label: 'Tracking mode', placeholder: 'Tracking mode' },
        { name: 'template_type', label: 'Template type', placeholder: 'Template type', customField: false }
    ];

    const [projectFields, setProjectFields] = useState<{ [key: string]: typeof defaultFields }[]>([]);
    const [searchParams] = useSearchParams();
    const code = searchParams.get('code');
    const [loading, setLoading] = useState<boolean>(true);

    const transformProjectData = (data: any) => {
        return {
            account_name: data.account_name,
            project_name: data.project_name,
            code: data.code,
            division: data.division,
            product_model: data.product_model,
            bg_image_url: data.bg_image_url,
            onboarding_audio_url: data.onboarding_audio_url,
            android_app_url: data.android_app_url,
            ios_app_url: data.ios_app_url,
            webgl_app_url: data.webgl_app_url,
            branding_bg_url: data.branding_bg_url,
            tracking_mode: data.tracking_mode,
            template_type: data.template_type,
            project_custom_fields: data.project_custom_fields,
        };
    };

    useEffect(() => {
        setProjectCount(projectData.length);
    }, [projectData]);

    useEffect(() => {
        const getData = async () => {
            try {
                const result = await fetchConfiguratorProjectData(String(code));
                if (result.status === 200) {
                    setProjectData([transformProjectData(result.data.project)]);
                }
            } catch (error) {
                setIsError(true);
                if (error instanceof Error) {
                    setError(error.message);
                } else {
                    setError("An unexpected error occurred");
                }
            } finally {
                setLoading(false);
            }
        };


        const getAccountsOpptions = async () => {
            try {
                const result = await fetchOptionsAccount();
                if (result.status === 200) {
                    setAccountOption(result.data.accounts);
                }
            } catch (error) {
                setIsError(true);
                if (error instanceof Error) {
                    setError(error.message);
                } else {
                    setError("An unexpected error occurred");
                }
            } finally {
                setLoading(false);
            }
        };

        if (code != null) {
            getData();
        } else {
            setProjectData([]);
            setProjectCount(0);
        }
        getAccountsOpptions();
    }, [code, setError, setIsError]);

    useEffect(() => {
        if (projectCount === 0 && projectData.length === 0) {
            if (accountOption.length !== 0) {
                const accountField = [{ name: 'account_name', label: 'Select account', type: 'select', placeholder: 'Select account', value: accountOption }];
                defaultFields.unshift(...accountField);
            }
            setProjectFields([{ project: [...defaultFields] }]);
        } else if (projectData.length > 0) {
            if (accountOption.length !== 0) {
                const accountField = [{ name: 'account_name', label: 'Select account', type: 'select', placeholder: 'Select account', value: accountOption }];
                defaultFields.unshift(...accountField);
            }
            const initialFields = Array.from({ length: projectCount }, () => ({
                project: [...defaultFields],
            }));
            initialFields[0] && projectData.forEach((data, index) => {
                const projectFields = initialFields[index]["project"];
                const projectCustomFields = data.project_custom_fields || {};
                const initialCustomFields = Object.values(projectCustomFields).map((customField: any) => ({
                    name: customField.attribute_value,
                    label: customField.attribute_value,
                    placeholder: customField.attribute_value,
                    value: customField.value,
                    customField: true,
                    type: customField.field_type,
                    id: customField.id
                }));

                if (projectFields) {
                    projectFields.push(...initialCustomFields);
                }
            });
            setProjectFields(initialFields);
        } else if (projectFields.length < projectCount) {
            setProjectFields(prev => {
                const newFields = Array.from({ length: projectCount - prev.length }, (_, index) => ({
                    [`project`]: [...defaultFields]
                }));
                return [...prev, ...newFields];
            });
        }
    }, [projectCount, projectData, accountOption]);
    const handleProjectChange = (
        e: React.ChangeEvent<HTMLInputElement>,
        index: number,
        field: boolean
    ) => {
        const { name, value, type } = e.target;
        setProjectData((prev: any[]) => {
            const updatedProject = [...prev];
            const project = updatedProject[index] || {};
            project[name] = value;
            if (field) {
                const projectCustomFields = project.project_custom_fields || {};
                const fieldIndex = Object.keys(projectCustomFields).findIndex(key => projectCustomFields[key].attribute_value === name);
                if (fieldIndex !== -1) {
                    projectCustomFields[fieldIndex] = {
                        ...projectCustomFields[fieldIndex],
                        value: value,
                    };
                } else {
                    projectCustomFields[Object.keys(projectCustomFields).length] = {
                        id: projectCustomFields[Object.keys(projectCustomFields).length]?.id,
                        attribute_value: name,
                        field_type: type,
                        value: value,
                    };
                }
                project.project_custom_fields = projectCustomFields;
            }
            updatedProject[index] = project;
            return updatedProject;
        });
    };


    const handleProjectSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            const result = await createProject(projectData[0]);
            if (result.status == 200) {
                navigate(`/configurator/form?page=stories&code=${result.data.code}`)
            }
        } catch (error) {
            setIsError(true);
            if (error instanceof Error) {
                setError(error.message);
            } else {
                setError("An unexpected error occurred");
            }
        } finally {
            setLoading(false);
        }
    };

    const handleDeleteCustomField = (id: any, name: any) => {
        const index = projectFields[0]["project"].findIndex((item: { name: any }) => item.name === name);
        if (index !== -1 && index) {
            setProjectData((prev: any[]) => {
                const updatedProject = prev.map(project => {
                    const projectCustomFields = [...(project.project_custom_fields || [])];
                    const fieldIndex = projectCustomFields.findIndex((item: { attribute_value: any }) => item.attribute_value === name);
                    if (fieldIndex !== -1) {
                        projectCustomFields.splice(fieldIndex, 1);
                    }
                    return {
                        ...project,
                        project_custom_fields: projectCustomFields
                    };
                });
                return updatedProject;
            });
            setProjectFields((prev: any) => {
                const updatedProject = [...prev];
                const project = updatedProject[0].project;
                project.splice(index, 1)
                return updatedProject;
            });
        }
        if (id !== undefined) {
            deleteConfiguratorProjectCustomField(id);
        }
    }

    const handleFieldCreateSubmit = (input: any, index: any) => {
        setProjectData((prev: any[]) => {
            const updatedProject = [...prev];
            const project = updatedProject[index] || {};
            const projectCustomFields = project.project_custom_fields || {};
            projectCustomFields[Object.keys(projectCustomFields).length] = {
                id: projectCustomFields[Object.keys(projectCustomFields).length]?.id,
                attribute_value: input.name,
                field_type: input.type,
                value: '',
            };
            project.project_custom_fields = projectCustomFields;
            updatedProject[index] = project;
            return updatedProject;
        });
    }

    return (
        <form onSubmit={handleProjectSubmit}>
            <div className="px-10 flex justify-end items-center">
                <button
                    type="submit"
                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                >
                    Save Project
                </button>
            </div>
            <div className='h-[73vh] w-full overflow-y-auto fixed bottom-0'>
                {Array.isArray(projectFields) && projectFields.map((fieldsObj, index) => {
                    const storyKey = Object.keys(fieldsObj)[0];
                    return (
                        <div key={index}>
                            <div className="px-10 py-5 text-lg flex">
                                <span className='mr-4 text-xl'>Project </span>
                                {index !== 0 && <button type="button" className="flex items-center"><FontAwesomeIcon icon={faTrash} className='text-sm' /></button>}
                            </div>
                            <div className='w-full h-auto flex'>
                                <div className='w-full px-5 flex flex-wrap'>
                                    <Form fields={fieldsObj[storyKey]} handleChange={(e: React.ChangeEvent<HTMLInputElement>, field) => handleProjectChange(e, index, field)} data={projectData[index] ?? []} handleDeleteCustomField={handleDeleteCustomField} />
                                    <div className="mb-5 mx-5 w-1/5 items-end justify-end flex">
                                        <CustomFieldCreateBtn index={index} keyName={`project`} setFieldsCount={setProjectFields} handleFieldCreateSubmit={(inputValue: any) => handleFieldCreateSubmit(inputValue, index)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </form>
    );
};

export default ProjectForm;