import React, { useState, useRef } from 'react';
import QRCode from 'qrcode.react';


interface QRCodeGeneratorProps {
    value: string;
  }

const QRCodeGenerator: React.FC<QRCodeGeneratorProps> = ({value}) => {
  const qrRef = useRef<HTMLDivElement>(null);

  const downloadQRCode = () => {
    if (qrRef.current) {
      const canvas = qrRef.current.querySelector('canvas');
      if (canvas) {
        const pngUrl = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');
        const downloadLink = document.createElement('a');
        downloadLink.href = pngUrl;
        downloadLink.download = `${value}.png`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      }
    }
  };

  return (
    <div className='flex justify-start items-center'>
      <div ref={qrRef} onClick={downloadQRCode} className='w-[20px] h-[20px] overflow-hidden'>
        <QRCode value={value} style={{ width: "20px", height: "20px" }}/>
      </div>
    </div>
  );
};

export default QRCodeGenerator;
