import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';

// Convert base64-encoded string to ArrayBuffer
const base64ToArrayBuffer = (base64: string): ArrayBuffer => {
  const binaryString = window.atob(base64);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes.buffer;
};

// Create a Blob from ArrayBuffer
const createBlobFromArrayBuffer = (arrayBuffer: ArrayBuffer, mimeType: string): Blob => {
  return new Blob([arrayBuffer], { type: mimeType });
};

// AudioPlayer Component
interface AudioPlayerProps {
  base64Data: string;
  mimeType: string;
}

const AudioPlayer: React.FC<AudioPlayerProps> = ({ base64Data, mimeType }) => {
  const audioRef = useRef<HTMLAudioElement | null>(null);

  // Convert base64 data to Blob and create URL
  useEffect(() => {
    const arrayBuffer = base64ToArrayBuffer(base64Data);
    const blob = createBlobFromArrayBuffer(arrayBuffer, mimeType);
    const audioUrl = URL.createObjectURL(blob);

    if (audioRef.current) {
      const audio = audioRef.current;
      audio.src = audioUrl;

      // Play the audio after it's fully loaded
      audio.onloadeddata = () => {
        audio.play().catch((error) => {
          console.error('Error playing audio:', error);
        });
      };
    }

    // Clean up URL object
    return () => {
      URL.revokeObjectURL(audioUrl);
    };
  }, [base64Data, mimeType]);

  return (
    <div>
      <audio ref={audioRef} controls>
        Your browser does not support the audio element.
      </audio>
    </div>
  );
};

const Configurator = () => {
  const [inputText, setInputText] = useState('');
  const [isAudio, setIsAudio] = useState(false);
  const [correctedText, setCorrectedText] = useState<string | null>(null);
  const [audioSrc, setAudioSrc] = useState<string | null>(null);

  const handleTextCorrection = async () => {
    try {
      const response = await axios.post(
        'https://api.openai.com/v1/audio/speech',
        {
          model: 'tts-1',
          input: inputText,
          voice: 'alloy',
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer sk-proj-mRQRbLUQ2cr0slpYrTevT3BlbkFJRzDNwglKBlUI2YkdUJoO`,
          },
          responseType: 'blob', // Important to specify response type as 'blob'
        }
      );
      if (audioSrc) {
        URL.revokeObjectURL(audioSrc);
      }
      // Assuming response contains the audio blob directly
      const audioBlob = response.data;
      const audioUrl = URL.createObjectURL(audioBlob);
      setAudioSrc(audioUrl);
      setIsAudio(true);
    } catch (error) {
      console.error('Error correcting text:', error);
    }
  };

  return (
    <div className='w-full h-full p-5'>
        <div className='w-full flex justify-center items-center text-2xl font-bold mb-10'>
            AI Integration
        </div>
      <div className='w-full mb-2'>
        <h3>Enter Audio Story:</h3>
        <p>{correctedText}</p>
      </div>
      <div className="w-full mb-4 border border-gray-200 rounded-lg bg-gray-50">
        <div className="px-4 py-2 bg-white rounded-t-lg">
          <label htmlFor="comment" className="sr-only">Your comment</label>
          <textarea 
            id="comment" 
            rows={4} 
            className="w-full px-0 text-sm text-gray-900 bg-white border-0 focus:ring-0" 
            placeholder="Write a comment..." 
            required
            value={inputText}
            onChange={(e) => setInputText(e.target.value)}
          ></textarea>
        </div>
        <div className="flex items-center justify-between px-3 py-2 border-t">
          <button 
            type="submit" onClick={handleTextCorrection}
            className="inline-flex items-center py-2.5 px-4 text-xs font-medium text-center text-white bg-blue-700 rounded-lg focus:ring-4 focus:ring-blue-200 hover:bg-blue-800"
          >
            Post comment
          </button>
        </div>
      </div>
      <div className='w-full mt-10'>
        <h3 className='mb-2'>Audio:</h3>
        {audioSrc && (
          <audio controls>
            <source src={audioSrc} type='audio/mpeg' />
            Your browser does not support the audio element.
          </audio>
        )}
      </div>
    </div>
  );
};

export default Configurator;
