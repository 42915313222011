import React, { ReactNode, useEffect } from "react";
import LoginSideImage from "./../assects/img/login_side_pic.png"
import { useNavigate } from "react-router-dom";
import Alert from "../components/Alert";
import { loginCheck } from "../services/apiService";

interface LoginLayoutProps {
    children: ReactNode;
    isError: boolean;
    error: string | null;
    setIsError: any;
  }

const LoginLayout: React.FC<LoginLayoutProps> = ({children, isError, error, setIsError}) => {
    const navigate = useNavigate();

    useEffect(() => {
        const getData = async () => {
            const result = await loginCheck();
            localStorage.setItem('isLoggedIn', result.authenticated?.toString())
            const isLoggedIn = localStorage.getItem('isLoggedIn') === 'true';
            if (isLoggedIn && result.authenticated) {
                navigate("/dashboard?page=1")
                window.location.reload();
            }
        }
        getData();
    }, [navigate]);

    const handleDismiss = (alertId: string) => {
        const alertElement = document.getElementById(alertId);
        if (alertElement) {
            setIsError(false);
            alertElement.style.display = 'none';
        }
    };
    
    return(
        <div className="w-full h-full flex">
            {isError &&
                <Alert
                    id="alert-2"
                    color="red"
                    iconColor="text-red-800 dark:text-red-400"
                    textColor="text-red-800 dark:text-red-400"
                    bgColor="bg-red-50 dark:bg-gray-800"
                    onDismiss={() => handleDismiss('alert-2')}
                >
                    {error !== null &&  error !== "" ? "Some error happen" : error}
                </Alert>}
            <div className="w-1/2 h-[100vh] bg-blue-600 flex justify-center items-end">
                <img src={LoginSideImage} alt="" width={"auto"} height={"100vh"} />
            </div>
            <div className="w-1/2 h-[100vh] flex justify-center items-center">
                {children}
            </div>
        </div>
    );
}
export default LoginLayout;