import React, { useState } from 'react';
import Pagination from '../components/Pagination';

interface TableViewLayoutProps {
    data: {
        clickable_value: any;
        clickable_link_type: any;
        headers: any[];
        values: any[];
        top: any;
        total_pages: number;
        current_page: number;
        title: string | null;
        sub_title: string;
    };
    handlePageChange: ((page: number) => Promise<void>);
}

const TableViewLayout: React.FC<TableViewLayoutProps> = ({ data, handlePageChange }) => {

    const handleRowClick = (url: string) => {
        window.location.href = url;
    };

    const copyToClipboard = (header: any) => {
        navigator.clipboard.writeText(header).then(() => {
            alert('URL copied!');
        }).catch(err => {
            console.error('Failed to copy text: ', err);
        });
    };

    return (
        <div>
            {data.title && <div className="w-full h-[10vh] p-1 flex justify-between items-center">
                <div className="w-1/2 h-full flex justify-start items-center">
                    <div>
                        <h2 className="text-2xl font-bold text-yellow-100">{data.title.replace(/[_-]/g, ' ').toUpperCase()}</h2>
                        <h5 className="text-xl font-medium text-yellow-100">{data.sub_title}</h5>
                    </div>
                </div>
                {data.top}
            </div>}
            <>
                <table className="w-full text-sm text-left text-black dark:text-black mt-2">
                    <thead className="text-xs text-black uppercase bg-gray-50 dark:text-black border-b-[15px] dark:bg-yellow-50/50 dark:border-yellow-500">
                        <tr>
                            {
                                data.headers.map((header: any, index: number) => (
                                    <th key={index} scope="col" className="px-6 py-3">
                                        {header.replace(/[_-]/g, ' ')}
                                    </th>
                                ))
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {data.values.map((value: any, index: number) => (
                            <tr
                                key={index}
                                className={`bg-white border-b-[8px] dark:bg-yellow-100 dark:border-yellow-500 ${data.clickable_value != null ? 'dark:hover:bg-yellow-600 cursor-pointer' : ''} `}
                            >
                                {Object.keys(data.values[0]).map((header: string, headerIndex: number) => (
                                    <td
                                        key={headerIndex}
                                        className={`px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-black ${header === "url" ? "truncate w-20" : ""} }`}
                                        onClick={() => {
                                            if (data.clickable_link_type == "dashboard" && header !== "qr" && header !== "url") {
                                                handleRowClick(`/dashboard/projects?project=${data.clickable_value[index].name}&page=1`);
                                            } else if (data.clickable_link_type == "configurator") {
                                                handleRowClick(`/configurator/form?page=project&code=${data.clickable_value[index].code}`);
                                            } else if (header === "url") {
                                                copyToClipboard(value[header])
                                            }
                                        }}
                                    >
                                        <span className={`block ${header === "url" ? "truncate text-blue-800 underline w-[100px]" : "w-full"} ${header === "qr" && "w-[20px]"}`} style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                            {value[header]}
                                        </span>
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
                {data.values && data.values.length <= 0 && (<div className='w-full h-[20vh] justify-center items-center flex font-medium'>Data is not available</div>)}
                <div className={`mt-3 flex justify-center items-center ${data.total_pages <= 1 ? 'hidden' : 'flex'}`}>
                    {Pagination({ data, handlePageChange })}
                </div>
            </>
        </div>
    );
}

export default TableViewLayout;