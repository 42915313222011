import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { createFeatures, createObjects, createProject, createStroies, fetchConfiguratorProjectData } from '../../../services/apiService';
import Breadcrumb from '../../../components/Breadcrumb';
import ProjectForm from '../../../components/form/ProjectForm';
import StoryForm from '../../../components/form/StoryForm';
import FeatureForm from '../../../components/form/FeatureForm';
import ObjectForm from '../../../components/form/ObjectForm';

interface ConfiguratorProjectProps {
  setError: Dispatch<SetStateAction<string | null>>;
  setIsError: Dispatch<SetStateAction<boolean>>;
}

const ConfiguratorProject: React.FC<ConfiguratorProjectProps> = ({ setError, setIsError }) => {
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');
  const page = searchParams.get('page');

  const breadcrumbs = [
    { label: 'Configurator', href: '/configurator?page=1' },
    { label: code ?? "New project", href: '' }
  ];

  return (
    <div className='w-full h-full font-bold text-wl'>
      <Breadcrumb breadcrumbs={breadcrumbs} />
      <div className='h-[15vh] w-full flex justify-center items-center'>
        <div className={`h-[5vh] w-1/5 border rounded-l-lg flex justify-center items-center ${page === "project" ? "text-blue-500 border-blue-500" : "text-black border-gray-300"}`}>
          Project
        </div>
        <div className={`h-[5vh] w-1/5 border flex justify-center items-center ${page === "stories" ? "text-blue-500 border-blue-500" : "text-black border-gray-300"}`}>
          Stories
        </div>
        <div className={`h-[5vh] w-1/5 border flex justify-center items-center ${page === "features" ? "text-blue-500 border-blue-500" : "text-black border-gray-300"}`}>
          Features
        </div>
        <div className={`h-[5vh] w-1/5 border rounded-r-lg flex justify-center items-center ${page === "objects" ? "text-blue-500 border-blue-500" : "text-black border-gray-300"}`}>
          Objects
        </div>
      </div>
      {page === "project" && (
        <ProjectForm setIsError={setIsError} setError={setError}/>
      )}
      {page === "stories" && (
        <StoryForm setIsError={setIsError} setError={setError} />
      )}
      {page === "features" && (
        <FeatureForm setError={setError} setIsError={setIsError}/>
      )}
      {page === "objects" && (
        <ObjectForm  setError={setError} setIsError={setIsError}/>
      )}
    </div>
  );
};

export default ConfiguratorProject;
