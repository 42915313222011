
import React from 'react';

interface AlertProps {
  children: string | null;
  id: string;
  color: "red";
  iconColor: string;
  textColor: string;
  bgColor: string;
  onDismiss: () => void;
}

// This function is for Alert
const Alert: React.FC<AlertProps> = ({ id, iconColor, textColor, bgColor, children, onDismiss}) => (
  <div id={id} className={`fixed top-0 left-0 flex justify-center items-center w-full mt-5`}>
    <div className={`flex items-center p-4 mb-4 rounded-lg ${bgColor} ${textColor}`} role="alert">
      <svg className={`flex-shrink-0 w-4 h-4 ${iconColor}`} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
      </svg>
      <span className="sr-only">Info</span>
      <div className="ms-3 text-sm font-medium mr-5">
        {children}
      </div>
      <button type="button" className={`ms-auto -mx-1.5 -my-1.5 ${bgColor} ${textColor} rounded-lg focus:ring-2 p-1.5 hover:bg-opacity-70 inline-flex items-center justify-center h-8 w-8`} aria-label="Close" onClick={onDismiss}>
        <span className="sr-only">Close</span>
        <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
          <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
        </svg>
      </button>
    </div>
  </div>
);

export default Alert;