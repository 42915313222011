import React, { useState } from "react";
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface CustomFieldCreateBtnProps {
    index: number;
    keyName: string;
    setFieldsCount: (fields: any) => void;
    handleFieldCreateSubmit?: any;
}

const CustomFieldCreateBtn: React.FC<CustomFieldCreateBtnProps> = ({ index, keyName, setFieldsCount, handleFieldCreateSubmit }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [error, setError] = useState('');
    const [inputValue, setInputValue] = useState({
        name: '',
        label: '',
        type: '',
        placeholder: '',
        customField: true
    });
    const [selectedType, setSelectedType] = useState("text");

const handleSelectChange = (event:any) => {
  setSelectedType(event.target.value);
};

const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.trim();
    const regex = /^[a-z_]+$/;

    if (regex.test(value) || value === '') {
        const field = {
            name: value, 
            label: value,
            placeholder: value,
            customField: true,
            type: selectedType
        };
        setInputValue(field);
        setError('');
    } else {
        setError("Use lowercase letters and underscores only, no spaces or hyphens.");
    }
};

    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    const handleFieldSubmit = () => {
        setFieldsCount((prevFields: any) => {
            const updatedFields = [...prevFields];
            if (keyName) {
                const fieldArray = updatedFields[index][keyName] || [];
                const newFieldArray = [...fieldArray, inputValue];
                updatedFields[index] = {
                    ...updatedFields[index],
                    [keyName]: newFieldArray,
                };
            } else {
                const newFieldArray = [...updatedFields, inputValue];
                return newFieldArray;
            }
            return updatedFields;
        });
        setIsModalOpen(false);
        handleFieldCreateSubmit(inputValue);
        setSelectedType("text");
    };

    return (
        <>
            <button
                type='button'
                className='shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5'
                onClick={toggleModal}
            >
                <FontAwesomeIcon icon={faPlus} className='text-sm' /> Add custom field
            </button>

            {isModalOpen && (
                <div
                    id="authentication-modal"
                    aria-hidden={!isModalOpen}
                    className="fixed inset-0 z-50 flex justify-center items-center w-full h-full bg-black bg-opacity-50"
                >
                    <div className="relative p-4 w-full max-w-md max-h-full">
                        <div className="relative bg-white rounded-lg shadow">
                            <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t">
                                <h3 className="text-xl font-semibold text-gray-900">
                                    Create custom field
                                </h3>
                                <button
                                    type="button"
                                    className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 inline-flex justify-center items-center"
                                    onClick={toggleModal}
                                >
                                    <svg
                                        className="w-3 h-3"
                                        aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 14 14"
                                    >
                                        <path
                                            stroke="currentColor"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M1 1l6 6m0 0l6 6M7 7l6-6M7 7L1 13"
                                        />
                                    </svg>
                                    <span className="sr-only">Close modal</span>
                                </button>
                            </div>

                            {/* Modal body */}
                            <div className="p-4 md:p-5">
                                {error && <div className="text-red-500 text-sm font-medium mb-5">{error}</div>}
                                <div>
                                    <label
                                        htmlFor="type"
                                        className="block my-2 text-sm font-medium text-black"
                                    >
                                        Field Type *
                                    </label>
                                    <select
                                        name="type"
                                        id="type"
                                        onChange={handleSelectChange}
                                        defaultValue="text"
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                    >
                                        <option value="text">Text</option>
                                        <option value="file">File</option>
                                        <option value="date">Date</option>
                                        <option value="number">Number</option>
                                    </select>
                                </div>
                                <div>
                                    <label
                                        htmlFor="field_label"
                                        className="block my-2 text-sm font-medium text-black"
                                    >
                                        Field Name *
                                    </label>
                                    <input
                                        type="text"
                                        name="name"
                                        id="field_label"
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                        required
                                        onChange={handleChange}
                                    />
                                </div>
                                <button
                                    type="button"
                                    onClick={handleFieldSubmit}
                                    className="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm mt-5 px-5 py-2.5 text-center"
                                >
                                    Create
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default CustomFieldCreateBtn;
