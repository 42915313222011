import React, { useState } from 'react';
type Placement = 'tooltip-right';

const Tooltip: React.FC<{ children: React.ReactNode; tooltipText: string; placement: Placement }> = ({ children, tooltipText, placement }) => {
  const [visible, setVisible] = useState(false);

  const placementClasses: Record<Placement, string> = {
    'tooltip-right': 'top-1/2 left-full ml-2 transform -translate-y-1/2',
  };

  return (
    <div
      className="relative inline-block z-50 w-full"
      onMouseEnter={() => setVisible(true)}
      onMouseLeave={() => setVisible(false)}
    >
      {children}
      {visible && (
        <div
          role="tooltip"
          className={`absolute ml-3 z-10 px-3 py-2 text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm ${placementClasses[placement]} whitespace-nowrap`}
        >
          {tooltipText}
        </div>
      )}
    </div>
  );
};

export default Tooltip;
