import React from 'react';import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouse, faChevronRight } from '@fortawesome/free-solid-svg-icons';

interface BreadcrumbItem {
    label: any;
    href?: string;
}

interface BreadcrumbProps {
    breadcrumbs: BreadcrumbItem[];
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({ breadcrumbs }) => {
    return (
        <nav className="flex p-5" aria-label="Breadcrumb">
            <ol className="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
                {breadcrumbs.map((breadcrumb, index) => (
                    <li className="inline-flex items-center" key={index}>
                        {breadcrumb.href !== ""? <a
                            href={breadcrumb.href}
                            className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600"
                        >
                            <FontAwesomeIcon icon={index === 0 ? faHouse : faChevronRight} className='mr-2' />
                            {breadcrumb.label}
                        </a>: <div className="inline-flex items-center text-sm font-medium text-gray-700"><FontAwesomeIcon icon={index === 0 ? faHouse : faChevronRight} className='mr-2' />
                        {breadcrumb.label}</div>}
                        
                    </li>
                ))}
            </ol>
        </nav>
    );
};

export default Breadcrumb;
