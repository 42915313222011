import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import LoginLayout from "../layouts/LoginLayout";
import SignUp from "../pages/auth/SignUp";
import Login from "../pages/auth/Login";
import Dashboard from "../pages/dashboard/Dashboard";
import SidebarLayout from "../layouts/SidebarLayout";
import PrivateRoute from "./PrivateRoute";
import Project from "../pages/dashboard/project/Project";
import Role from "../pages/role/Role";
import Integration from "../pages/integration/Integration";
import Configurator from "../pages/configurator/Configurator";
import { useEffect, useState } from "react";
import ConfiguratorProject from "../pages/configurator/forms/ConfiguratorProject";

function MainContent() {
    const [error, setError] = useState<string | null>(null);
    const [isError, setIsError] = useState<boolean>(false);
    const location = useLocation();
    const hideSidebarRoutes = ['/login', '/signup', '/logout'];
    const [isAuthenticated, setIsAuthenticated] = useState(false);
  
    const shouldHideSidebar = hideSidebarRoutes.includes(location.pathname);
  
    useEffect(() => {
      if (localStorage.getItem('isLoggedIn') === 'true') {
        setIsAuthenticated(true);
      }
    }, []);
  
    return (
      shouldHideSidebar ? (
        <LoginLayout isError={isError} error={error} setIsError={setIsError}>
        <Routes>
            <Route path="/login" element={<Login setError ={setError} setIsError={setIsError}/>} />
            <Route path="/signup" element={<SignUp  setError ={setError} setIsError={setIsError}/>} />
          </Routes>
        </LoginLayout>) : (
        <SidebarLayout isError={isError} error={error} setIsError={setIsError}>
          <Routes>
            <Route path="/dashboard" element={<PrivateRoute element={<Dashboard setError ={setError} setIsError={setIsError}/>} />} />
              <Route path="/dashboard/projects" element={<PrivateRoute element={<Project setError ={setError} setIsError={setIsError}/>} />} />
              <Route path="/configurator" element={<PrivateRoute element={<Configurator setError ={setError} setIsError={setIsError}/>} />} />
              <Route path="/configurator/form" element={<PrivateRoute element={<ConfiguratorProject setError ={setError} setIsError={setIsError}/>} />} />
              <Route path="/integration" element={<PrivateRoute element={<Integration />} />} />
              <Route path="/roles" element={<PrivateRoute element={<Role setError ={setError} setIsError={setIsError}/>} />} />
              <Route path="/" element={<Navigate to={isAuthenticated ? "/dashboard?page=1" : "/login"} />} />
            </Routes>
        </SidebarLayout>)
    );
  }

  export default MainContent;