import { useEffect, useState } from "react";
import Form from "./Form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import CustomFieldCreateBtn from "../CustomFieldCreateBtn";
import { createStroies, deleteConfiguratorStoryCustomField, fetchConfiguratorStoryData } from "../../services/apiService";
import { useNavigate, useSearchParams } from "react-router-dom";

interface StoryFormProps {
    setError: any;
    setIsError: any;
}

const StoryForm: React.FC<StoryFormProps> = ({ setError, setIsError }) => {
    const [storyCount, setStoryCount] = useState(0);
    const [storyData, setStoryData] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const navigate = useNavigate();
    const fields = [
        { name: 'name', label: 'Name', placeholder: 'Story name' },
        { name: 'audio_url', label: 'Audio URL', placeholder: 'Audio URL' },
        { name: 'animation_type', label: 'Animation type', placeholder: 'Animation type' },
        { name: 'description', label: 'Description', placeholder: 'Description' },
        { name: 'icon_url', label: 'Icon URL', placeholder: 'Icon URL' },
        { name: 'storyline_type', label: 'Storyline type', placeholder: 'Storyline type', customField: false },
        { name: 'object_url', label: 'Object URL', placeholder: 'Object URL', value: '' }
    ];
    const [fieldsCount, setFieldsCount] = useState<{ [key: string]: typeof fields }[]>([]);
    const [searchParams] = useSearchParams();
    const code = searchParams.get('code');
    const transformData = (data: any) => {
        return {
            name: data.name,
            audio_url: data.audio_url,
            animation_type: data.animation_type,
            description: data.description,
            storyline_type: data.storyline_type,
            icon_url: data.icon_url,
            object_url: data.object_url,
            story_custom_fields: data.story_custom_fields,
        };
    };

    useEffect(() => {
        setStoryCount(storyData.length);
    }, [storyData]);

    useEffect(() => {
        const getData = async () => {
            try {
                const result = await fetchConfiguratorStoryData(String(code));
                if (result.status === 200) {
                    const transformedStories = result.data.stories.map((data: any) => transformData(data));
                    setStoryData(transformedStories);
                }
            } catch (error) {
                setIsError(true);
                if (error instanceof Error) {
                    setError(error.message);
                } else {
                    setError("An unexpected error occurred");
                }
            } finally {
                setLoading(false);
            }
        };

        if (code != null) {
            getData();
        } else {
            setStoryData([]);
            setStoryCount(0);
        }
    }, [code, setError, setIsError]);

    const handleStoriesSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            const result = await createStroies(storyData, code);
            if (result.status == 200) {
                navigate(`/configurator/form?page=features&code=${code}`)
            }
        } catch (error) {
            setIsError(true);
            if (error instanceof Error) {
                setError(error.message);
            } else {
                setError("An unexpected error occurred");
            }
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (storyCount === 0 && storyData.length === 0) {
            setFieldsCount([{ story0: [...fields] }]);
        } else if (storyData.length > 0) {
            const initialFields = Array.from({ length: storyCount }, (_, index) => ({
                [`story${index}`]: [...fields],
            }));

            initialFields[0] && storyData.forEach((data, index) => {
                const storyFields = initialFields[index][`story${index}`] || [];
                const storyCustomFields = data.story_custom_fields || {};
                const initialCustomFields = Object.values(storyCustomFields).map((customField: any) => ({
                    name: customField.attribute_value,
                    label: customField.attribute_value,
                    placeholder: customField.attribute_value,
                    value: customField.value,
                    customField: true,
                    type: customField.field_type,
                    id: customField.id
                }));

                if (storyFields) {
                    storyFields.push(...initialCustomFields);
                }
            });
            setFieldsCount(initialFields);
        } else if (fieldsCount.length < storyCount) {
            setFieldsCount(prev => {
                const newFields = Array.from({ length: storyCount - prev.length }, (_, index) => ({
                    [`story${prev.length + index}`]: [...fields]
                }));
                return [...prev, ...newFields];
            });
        }

    }, [storyCount, storyData]);

    const handleStoryChange = (
        e: React.ChangeEvent<HTMLInputElement>,
        index: number,
        field: boolean
    ) => {
        const { name, value, type } = e.target;

        setStoryData((prev) => {
            const updatedStories = [...prev];
            const story = updatedStories[index] || {};
            story[name] = value;
            if (field) {
                const storyCustomFields = story.story_custom_fields || {};
                const fieldIndex = Object.keys(storyCustomFields).findIndex(key => storyCustomFields[key].attribute_value === name);
                if (fieldIndex !== -1) {
                    storyCustomFields[fieldIndex] = {
                        ...storyCustomFields[fieldIndex],
                        value: value,
                    };
                } else {
                    storyCustomFields[Object.keys(storyCustomFields).length] = {
                        id: storyCustomFields[Object.keys(storyCustomFields).length]?.id,
                        attribute_value: name,
                        field_type: type,
                        value: value,
                    };
                }
                story.story_custom_fields = storyCustomFields;
            }
            updatedStories[index] = story;
            return updatedStories;
        });
    };


    const handleFieldsDeletion = (storyKey: string) => {
        const storyIndex = fieldsCount.findIndex(item => storyKey in item);
        if (storyIndex === -1) {
            console.log('Story key not found');
            return;
        }
        setStoryData((prev: any) => {
            const updatedStories = prev.filter((_: any, index: number) => index !== storyIndex);
            return updatedStories;
        });
        setFieldsCount((prev) => {
            const newFieldsCount = prev.filter((story, index) => {
                return Object.keys(story)[0] !== storyKey;
            });
            return newFieldsCount;
        });
        setStoryCount((prev: any) => prev - 1);
    };

    const handleDeleteCustomField = (id: any, name: any, index: any) => {
        const fieldIndex = fieldsCount[index][`story${index}`].findIndex((item: { name: any }) => item.name === name);
        if (fieldIndex !== -1 && fieldIndex) {
            setStoryData((prev: any[]) => {
                const updatedStory = prev.map(story => {
                    const storyCustomFields = [...(story.story_custom_fields || [])];
                    const fieldIndex = storyCustomFields.findIndex((item: { attribute_value: any }) => item.attribute_value === name);
                    if (fieldIndex !== -1) {
                        storyCustomFields.splice(fieldIndex, 1);
                    }
                    return {
                        ...story,
                        story_custom_fields: storyCustomFields
                    };
                });
                return updatedStory;
            });
            setFieldsCount((prev: any) => {
                const updatedStory = [...prev];
                const story = updatedStory[index][`story${index}`];
                story.splice(fieldIndex, 1)
                return updatedStory;
            });
        }
        if (id !== undefined) {
            deleteConfiguratorStoryCustomField(id);
        }
    }

    const handleFieldCreateSubmit = (input: any, index: any) => {
        setStoryData((prev: any[]) => {
            const updatedStory = [...prev];
            const story = updatedStory[index] || {};
            const storyCustomFields = story.story_custom_fields || {};
            storyCustomFields[Object.keys(storyCustomFields).length] = {
                id: storyCustomFields[Object.keys(storyCustomFields).length]?.id,
                attribute_value: input.name,
                field_type: input.type,
                value: '',
            };
            story.story_custom_fields = storyCustomFields;
            updatedStory[index] = story;
            return updatedStory;
        });
    }

    return (
        <form onSubmit={handleStoriesSubmit}>
            <div className="px-10 flex justify-between items-center">
                <button
                    type='button'
                    onClick={() => setStoryCount((prev: any) => prev + 1)}
                    className="text-blue-600 bg-blue-700 border border-blue-600 hover:bg-blue-800 hover:text-white bg-transparent rounded-lg px-3 py-1.5 text-center text-sm"
                >
                    <FontAwesomeIcon icon={faPlus} className='text-sm' />  Add story
                </button>
                <button
                    type="submit"
                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                >
                    Save Story
                </button>
            </div>
            <div className='h-[73vh] w-full overflow-y-auto fixed bottom-0'>
                {Array.isArray(fieldsCount) && fieldsCount.map((fieldsObj, index) => {
                    const storyKey = Object.keys(fieldsObj)[0];
                    return (
                        <div key={index}>
                            <div className="px-10 py-5 text-lg flex">
                                <span className='mr-4 text-xl'>Story {index + 1}</span>
                                {/* {index !== 0 && <button type="button" onClick={() => handleFieldsDeletion(storyKey)} className="flex items-center"><FontAwesomeIcon icon={faTrash} className='text-sm' /></button>} */}
                            </div>
                            <div className='w-full h-auto flex'>
                                <div className='w-full px-5 flex flex-wrap'>
                                    <Form fields={fieldsObj[storyKey]} handleChange={(e: React.ChangeEvent<HTMLInputElement>, field) => handleStoryChange(e, index, field)} data={storyData[index] ?? []} handleDeleteCustomField={(id: any, name: any) => handleDeleteCustomField(id, name, index)} />
                                    <div className="mb-5 mx-5 w-1/5 items-end justify-end flex">
                                        <CustomFieldCreateBtn index={index} keyName={`story${index}`} setFieldsCount={setFieldsCount} handleFieldCreateSubmit={(inputValue: any) => handleFieldCreateSubmit(inputValue, index)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </form>
    );
};

export default StoryForm;
