import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";

function PrivateRoute({ element }: { element: JSX.Element }) {
    const [isLoggedIn] = useState(() => {
      return localStorage.getItem('isLoggedIn') === 'true';
    });
  
    useEffect(() => {
      localStorage.setItem('isLoggedIn', JSON.stringify(isLoggedIn));
    }, [isLoggedIn]);
    
    return isLoggedIn ? element : <Navigate to="/login" />;
  }

  export default PrivateRoute;
