import { Dispatch, SetStateAction, useEffect, useState } from "react";
import React from "react";
import { useSearchParams } from "react-router-dom";
import Pagination from "../../components/Pagination";
import SignUp from "../auth/SignUp"
import { fetchUsers, signUp } from "../../services/apiService";

interface RoleProps {
    setError: Dispatch<SetStateAction<string | null>>;
    setIsError: Dispatch<SetStateAction<boolean>>;
}

const Role: React.FC<RoleProps> = ({ setError, setIsError }) => {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen((cur) => !cur);
    const [data, setData] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [searchParams, setSearchParams] = useSearchParams();
    const page = searchParams.get('page');
    const [organization, setOrganization] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [role, setRole] = useState(1);

    const [isOpen, setIsOpen] = useState(false);

    const toggleModal = () => {
        setIsOpen(!isOpen);
    };

    const [selectedValue, setSelectedValue] = useState('');

    const handleChange = (event: any) => {
        setSelectedValue(event);
    };

    const handleChangeRole = (event: any) => {
        setRole(event);
    };

    useEffect(() => {
        const getData = async () => {
            try {
                const result = await fetchUsers(Number(page));
                setData(result);
                setOrganization(result.organization);
            } catch (error) {
                setIsError(true);
                if (error instanceof Error) {
                    setError(error.message);
                } else {
                    setError("An unexpected error occurred");
                }
            } finally {
                setLoading(false);
            }
        };
        getData();
    }, [page, setError, setIsError]);
    const updatePageParam = (newPage: any) => {
        searchParams.set('page', newPage.toString());
        setSearchParams(searchParams);
    };
    const handlePageChange = async (page: Number) => {
        updatePageParam(page);
        const result = await fetchUsers(page);
        setData(result);
    };
    const handleSubmit = async (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        try {
            const result = await signUp(String(organization), String(name), String(email), String(phone), String(password), String(confirmPassword), Number(role));
            if (result.status == 201) {
                setIsOpen(false);
                window.location.reload();
            }
        } catch (error) {
            setIsError(true);
            if (error instanceof Error) {
                setError(error.message);
            } else {
                setError("An unexpected error occurred");
            }
        }
    };

    return (
        <div className="w-full">
            <div className="w-full h-auto p-5">
                <div>
                    <p className="font-medium text-sm px-5">Manage member access</p>
                </div>
                <div className="flex mt-1 px-5">
                    <div className="w-full flex justify-start items-center">
                        <h1 className="text-3xl font-bold">Members</h1>
                    </div>
                    {data && <div className={`w-full flex justify-end items-center ${data.role !== "super_admin" && data.role !== "admin" ? "hidden" : "block"}`}>
                        <button type="button" onClick={toggleModal} className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2 mr-5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">+ Add members</button>
                    </div>}
                </div>
                <div className="flex w-full h-full mt-6">
                    <div className="w-2/3 h-full p-5">
                        <div className="flow-root">
                            <ul role="list">
                                {data && data.users.map((user: any, index: number) => (
                                    <li key={index} className="pb-5">
                                        <div className="flex items-center space-x-4">
                                            <div className={`flex-shrink-0 flex justify-center items-center text-white w-10 h-10 rounded-lg bg-indigo-500`}>
                                                {user.name.charAt(0).toUpperCase()}
                                            </div>
                                            <div className="flex-1 min-w-0">
                                                <p className="text-sm font-medium text-gray-900 truncate">
                                                    {user.name}
                                                </p>
                                                <p className="text-sm text-gray-500 truncate">
                                                    {user.email}
                                                </p>
                                            </div>
                                            <div>
                                                {data && data.role !== "super_admin" && data.role !== "admin" ? <div>{user.role.label}</div> :
                                                    <select
                                                        id="countries"
                                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                        defaultValue={"Sales"}
                                                        onChange={e => handleChange(e.target.value)}
                                                    >
                                                        <option value={user.role.id}>{user.role.label}</option>
                                                        {data.roles && data.roles.map((role: any, index: any) => (
                                                            role.name !== user.role.name ? (
                                                                <option key={index} value={role.id}>{role.label}</option>
                                                            ) : null
                                                        ))}
                                                    </select>}
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className="flex justify-between items-center mt-4">
                            {data && <div className={`w-2/3 mt-3 ${data.total_pages <= 1 ? 'hidden' : 'flex'}`}>
                                {Pagination({ data, handlePageChange })}
                            </div>}
                            {data && <button type="button" onClick={handleOpen} className={`text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 ${data.role !== "super_admin" && data.role !== "admin" ? "hidden" : "block"}`}>Save</button>}
                        </div>
                    </div>
                    <div className="w-1/3 h-full py-5 px-10 text-gray-500 font-normal text-sm">
                        <p>A member access page restricts specific content or services to registered users only. Visitors must log in with their credentials to gain access. This ensures that sensitive or premium information is protected and only available to authorized users.</p>
                        <p className="pt-5">It enhances security, provides personalized user experiences, and can offer exclusive benefits or content, encouraging user registration and engagement on the platform.</p>
                    </div>
                </div>

            </div>
            <div>
                {isOpen && (
                    <div
                        id="authentication-modal"
                        aria-hidden="true"
                        className="fixed inset-0 z-50 flex items-center justify-center w-full h-full overflow-y-auto bg-black bg-opacity-50"
                    >
                        <div className="relative p-4 w-full max-w-md max-h-full">
                            <div className="relative bg-white rounded-lg shadow">
                                <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t">
                                    <h3 className="text-xl font-semibold text-gray-900">
                                        Add member
                                    </h3>
                                    <button
                                        type="button"
                                        className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 inline-flex justify-center items-center"
                                        onClick={toggleModal}
                                    >
                                        <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                        </svg>
                                        <span className="sr-only">Close modal</span>
                                    </button>
                                </div>
                                {/* Modal body */}
                                <div className="p-4 md:p-5">
                                    <form className="space-y-4" onSubmit={handleSubmit}>
                                        <div>
                                            <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900">Name</label>
                                            <input
                                                type="text"
                                                name="name"
                                                id="name"
                                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                                placeholder="Name"
                                                required
                                                value={name}
                                                onChange={(e) => setName(e.target.value)}
                                            />
                                        </div>
                                        <div>
                                            <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900">Email</label>
                                            <input
                                                type="email"
                                                name="email"
                                                id="email"
                                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                                placeholder="name@company.com"
                                                required
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                            />
                                        </div>
                                        <div>
                                            <label htmlFor="phone" className="block mb-2 text-sm font-medium text-gray-900">Phone</label>
                                            <input
                                                type="text"
                                                name="phone"
                                                id="phone"
                                                value={phone}
                                                onChange={(e) => setPhone(e.target.value)}
                                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                            />
                                        </div>
                                        <div>
                                            <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900">Password</label>
                                            <input
                                                type="password"
                                                name="password"
                                                id="password"
                                                placeholder="••••••••"
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)}
                                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                                required
                                            />
                                        </div>
                                        <div>
                                            <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900">Password confermation</label>
                                            <input
                                                type="password"
                                                name="confirmPassword"
                                                id="confirmPassword"
                                                placeholder="••••••••"
                                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                                required
                                                value={confirmPassword}
                                                onChange={(e) => setConfirmPassword(e.target.value)}
                                            />
                                        </div>
                                        <div>
                                            <label htmlFor="role" className="block mb-2 text-sm font-medium text-gray-900">Role</label>
                                            {data && <select
                                                id="role"
                                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                defaultValue="Sales"
                                                onChange={e => handleChangeRole(e.target.value)}
                                            >
                                                {data.roles &&
                                                    data.roles.map((role: any, roleIndex: number) =>
                                                            <option key={roleIndex} value={role.id}>{role.label}</option>
                                                    )}
                                            </select>
                                            }
                                        </div>
                                        <button
                                            type="submit"
                                            className="mt-10 w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                                        >
                                            Add
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}
export default Role;