import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';

interface Field {
    name: string;
    label: string;
    placeholder?: string;
    disabled?: boolean;
    type?: string;
    value?: string | any;
    customField?: boolean;
    id?: any;
}

interface FormProps {
    fields: Field[];
    handleChange: (event: React.ChangeEvent<HTMLInputElement>, field: any) => void;
    data: any;
    handleDeleteCustomField?: any;
}

const Form: React.FC<FormProps> = (props) => {
    const [formValues, setFormValues] = useState<{ [key: string]: any }>({});

    useEffect(() => {
        props.data && setFormValues(
            props.fields.reduce((acc, field) => ({
                ...acc,
                [field.name]: props.data[field.name] ?? field.value ?? ""
            }), {})
        );
    }, [props.data])

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>, field: boolean) => {
        const { name, value } = event.target;
        setFormValues({
            ...formValues,
            [name]: value
        });
        props.handleChange(event, field);
    };
    const handleDeleteCustomField = (id:any, name:any) => {
        delete formValues[name];
        props.handleDeleteCustomField(id, name)
    }

    return (
        <>
            {props.fields.map((field) => (
                <div className="mb-5 mx-5 w-1/5" key={field.name}>
                    <label htmlFor={field.name} className="mb-2 text-sm font-medium text-gray-900 flex justify-start items-center">
                        {field.label?.split('_')
                            .map((word, i) =>
                                i === 0
                                    ? word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
                                    : word.toLowerCase()
                            ).join(' ')} <span className='ml-2'>{field.customField && <button type="button" className="flex items-center" onClick={() => handleDeleteCustomField(field.id, field.name)} ><FontAwesomeIcon icon={faTrash} className='text-sm' /></button>}</span>
                    </label>
                    {field.type != "select" && <input
                        type={field.type ?? "text"}
                        name={field.name}
                        id={field.name}
                        className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 w-full p-2.5"
                        placeholder={field.placeholder?.split('_')
                            .map((word, i) =>
                                i === 0
                                    ? word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
                                    : word.toLowerCase()
                            ).join(' ')}
                        disabled={field.disabled ?? false}
                        value={formValues[field.name] !== undefined ? formValues[field.name] : ""}
                        onChange={(e) => handleChange(e, field.customField ?? false)}
                    />}
                    {field.type == "select" && (field.name == "account_name" || field.name == "story_name") &&
                        <select name={field.name} value={formValues[field.name]} id={field.name} onChange={(e: any) => handleChange(e, field.customField ?? false)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                            {
                                field.value.map((story: any, i: any) => (
                                    <option value={story} key={i}>{story}</option>
                                ))
                            }
                        </select>}
                    {field.type == "select" && field.name == "feature_name" &&
                        <select name={field.name} value={formValues[field.name]} id={field.name} onChange={(e: any) => handleChange(e, field.customField ?? false)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                            {field.value[formValues["story_name"]]?.map((feature: string, i: number) => (
                                <option value={feature} key={i}>{feature}</option>
                            )) || <option value="">Select Story</option>}
                        </select>}
                </div>
            ))}
        </>
    );
};

export default Form;