import { useEffect, useState } from "react";
import Form from "./Form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { createObjects, deleteConfiguratorObjectCustomField, fetchConfiguratorObjectssData } from "../../services/apiService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import CustomFieldCreateBtn from "../CustomFieldCreateBtn";

interface ObjectFormProps {
    setError: any;
    setIsError: any;
}

const ObjectForm: React.FC<ObjectFormProps> = (props) => {
    const [searchParams] = useSearchParams();
    const code = searchParams.get('code');
    const [loading, setLoading] = useState<boolean>(true);
    const [objectCount, setObjectCount] = useState(0);
    const [storiesOption, setStoriesOption] = useState<any>([]);
    const [featureData, setFeatureData] = useState<any>({});
    const [featureOption, setFeatureOption] = useState<any>([]);
    const [objectData, setObjectData] = useState<any>([]);
    const navigate = useNavigate();

    useEffect(() => {
        setObjectCount(objectData.length);
    }, [objectData]);
  
    useEffect(() => {
        const getFeatureData = async () => {
            try {
                const result = await fetchConfiguratorObjectssData(String(code));
                if (result.status == 200) {
                    setObjectData(result.data.objects);
                    setStoriesOption(Object.keys(result.data.features));
                    setFeatureData(result.data.features);
                    setFeatureOption(result.data.features)
                }
            } catch (error) {
                props.setIsError(true);
                if (error instanceof Error) {
                    props.setError(error.message);
                } else {
                    props.setError("An unexpected error occurred");
                }
            } finally {
                setLoading(false);
            }
        };
        if (code != null) {
            getFeatureData();
        }
    }, [code, props.setError, props.setIsError]);

    const fields = [
        { name: 'story_name', label: 'Select Story', placeholder: 'Select Story', type: 'select', value: storiesOption },
        { name: 'feature_name', label: 'Select Feature', placeholder: 'Select Feature', type: 'select', value: featureOption},
        { name: 'name', label: 'Name', placeholder: 'Story name' },
        { name: 'icon_url', label: 'Icon url', placeholder: 'Icon url' },
        { name: 'object_url', label: 'Object url', placeholder: 'Object url', customField: false, id: undefined }
    ];
    const [fieldsCount, setFieldsCount] = useState<{ [key: string]: typeof fields }[]>([]);
    
    useEffect(() => {
        if (objectCount === 0 && objectData.length === 0) {
            setFieldsCount([{ object0: [...fields] }]);
        } else if (objectData.length > 0) {
            const initialFields = Array.from({ length: objectCount }, (_, index) => ({
                [`object${index}`]: [...fields],
            }));

            initialFields[0] && objectData.forEach((data: any, index: any) => {
                const objectFields = initialFields[index][`object${index}`] || [];
                const objectCustomFields = data.object_custom_fields || {};
                const initialCustomFields = Object.values(objectCustomFields).map((customField: any) => ({
                    name: customField.attribute_value,
                    label: customField.attribute_value,
                    placeholder: customField.attribute_value,
                    type: customField.field_type,
                    value: customField.value,
                    customField: true,
                    id: customField.id
                }));

                if (objectFields) {
                    objectFields.push(...initialCustomFields);
                }
            });
            setFieldsCount(initialFields);
        } else if (fieldsCount.length < objectCount) {
            setFieldsCount(prev => {
                const newFields = Array.from({ length: objectCount - prev.length }, (_, index) => ({
                    [`object${prev.length + index}`]: [...fields]
                }));
                return [...prev, ...newFields];
            });
        }
    }, [objectCount, objectData]);

    const handleObjectChange = (
        e: React.ChangeEvent<HTMLInputElement>,
        index: number,
        field: boolean
    ) => {
        const { name, value, type } = e.target;
        setObjectData((prev: any) => {
            const updatedObjects = [...prev];
            const object = updatedObjects[index] || {};
            object[name] = value;
            if (field) {
                const objectCustomFields = object.object_custom_fields || {};
                const fieldIndex = Object.keys(objectCustomFields).findIndex(key => objectCustomFields[key].attribute_value === name);
                if (fieldIndex !== -1) {
                    objectCustomFields[fieldIndex] = {
                        ...objectCustomFields[fieldIndex],
                        value: value,
                    };
                } else {
                    objectCustomFields[Object.keys(objectCustomFields).length] = {
                        id: objectCustomFields[Object.keys(objectCustomFields).length]?.id,
                        attribute_value: name,
                        field_type: type,
                        value: value,
                    };
                }
                object.object_custom_fields = objectCustomFields;
            }
            updatedObjects[index] = object;
            return updatedObjects;
        });
    };
    const handleFieldsDeletion = (objectKey: string) => {
        const objectIndex = fieldsCount.findIndex(item => objectKey in item);
        if (objectIndex === -1) {
            console.log('object key not found');
            return;
        }
        setObjectData((prev: any) => {
            const updatedFeatures = prev.filter((_: any, index: number) => index !== objectIndex);
            return updatedFeatures;
        });
        setFieldsCount((prev) => {
            const newFieldsCount = prev.filter((object) => {
                return Object.keys(object)[0] !== objectKey;
            });
            return newFieldsCount;
        });
        setObjectCount((prev: any) => prev - 1);
    };

    const handleObjectsSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
          const result = await createObjects(objectData, code);
          if (result.status == 200) {
            navigate(`/configurator?page=1`)
          }
        } catch (error) {
          props.setIsError(true);
          if (error instanceof Error) {
            props.setError(error.message);
          } else {
            props.setError("An unexpected error occurred");
          }
        } finally {
          setLoading(false);
        }
      };

      const handleDeleteCustomField = (id: any, name: any, index: any) => {
        const fieldIndex = fieldsCount[index][`object${index}`].findIndex((item: { name: any }) => item.name === name);
        if(fieldIndex !== -1 && fieldIndex){
                setObjectData((prev: any[]) => {
                    const updatedObject = prev.map(object => {
                        const objectCustomFields = Object.values(object.object_custom_fields) || [];
                        
                        const fieldIndex = objectCustomFields.findIndex((item: any) => item.attribute_value === name);
                        if (fieldIndex !== -1) {
                            objectCustomFields.splice(fieldIndex, 1);
                        }
                        return {
                            ...object,
                            object_custom_fields: objectCustomFields
                        };
                    });
                    return updatedObject;
                });
            setFieldsCount((prev: any) => {
                const updatedObject = [...prev];
                const feature = updatedObject[index][`object${index}`];
                feature.splice(fieldIndex, 1)
                return updatedObject;
            });
        }
        if (id !== undefined) {
            deleteConfiguratorObjectCustomField(id);
        }
    }

    const handleFieldCreateSubmit = (input:any, index: any) => {
        setObjectData((prev: any[]) => {
            const updatedObject = [...prev];
            const object = updatedObject[index] || {};
            const objectCustomFields = object.object_custom_fields || {};
            objectCustomFields[Object.keys(objectCustomFields).length] = {
                id: objectCustomFields[Object.keys(objectCustomFields).length]?.id,
                attribute_value: input.name,
                field_type: input.type,
                value: '',
            };
            object.object_custom_fields = objectCustomFields;
            updatedObject[index] = object;
            return updatedObject;
        });
    }

    return (
        <form onSubmit={handleObjectsSubmit}>
            <div className="px-10 flex justify-between items-center">
                <button
                    type='button'
                    onClick={() => setObjectCount((prev: any) => prev + 1)}
                    className="text-blue-600 bg-blue-700 border border-blue-600 hover:bg-blue-800 hover:text-white bg-transparent rounded-lg px-3 py-1.5 text-center text-sm"
                >
                    <FontAwesomeIcon icon={faPlus} className='text-sm' />  Add object
                </button>
                <button
                    type="submit"
                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                >
                    Save object
                </button>
            </div>
            <div className='h-[73vh] w-full overflow-y-auto fixed bottom-0'>
                {Array.isArray(fieldsCount) && fieldsCount.map((fieldsObj, index) => {
                    const objectKey = Object.keys(fieldsObj)[0];
                    return (
                        <div key={index}>
                            <div className="px-10 py-5 text-lg flex">
                                <span className='mr-4 text-xl'>Object {index + 1}</span>
                                {/* {index !== 0 && <button type="button" onClick={() => handleFieldsDeletion(objectKey)} className="flex items-center"><FontAwesomeIcon icon={faTrash} className='text-sm' /></button>} */}
                            </div>
                            <div className='w-full h-auto flex'>
                                <div className='w-full px-5 flex flex-wrap'>
                                    <Form fields={fieldsObj[objectKey]} handleChange={(e: React.ChangeEvent<HTMLInputElement>, field) => handleObjectChange(e, index, field)} data={objectData[index] ?? []}  handleDeleteCustomField={(id:any, name:any) => handleDeleteCustomField(id, name, index)}/>
                                    <div className="mb-5 mx-5 w-1/5 items-end justify-end flex">
                                        <CustomFieldCreateBtn index={index} keyName={`object${index}`} setFieldsCount={setFieldsCount} handleFieldCreateSubmit={(inputValue:any) => handleFieldCreateSubmit(inputValue, index)}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </form>
    );
};

export default ObjectForm;